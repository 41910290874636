<template>
    <header class="header">
        <a :href="$route.meta.botaoSairPdv ? `${this.$urlBase}/vendas_balcao` : `${this.$urlBase}/inicio`" class="logo pt-2 pt-md-0">
            <img :src="this.$app.caminho_logomarca" border="0" width="95%" :title="this.$app.nome" v-cloak />
        </a>
        <div class="d-flex justify-content-end" v-if="$isMobile()" :style="`background-color: #${$app.cor_primaria}`">
            <!-- <Versao /> -->
        </div>
        <nav class="navbar navbar-expand-lg" role="navigation">
            <a v-if="!$route.meta.botaoSairPdv" href="#" @click.prevent="alternarBarraLateral" class="navbar-btn sidebar-toggle" data-toggle="offcanvas" role="button">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </a>
            <div class="navbar-right">
                <ul class="nav navbar-nav m-0">
                    <slot name="botoes" />
                    <li v-if="$route.meta.botaoSairPdv" class="nav-item dropdown">
                        <b-link class="nav-link" :to="`${$urlBase}/vendas_balcao`">
                            <i class="fa fa-sign-out" />
                        </b-link>
                    </li>
                    <template v-else>
                        <!-- <slot v-if="!$isMobile()" name="versao">
                            <Versao />
                        </slot> -->
                        <slot name="notificacoes">
                            <notificacoes />
                        </slot>
                        <slot name="ajuda">
                            <ajuda />
                        </slot>
                        <slot name="configuracoes">
                            <configuracoes/>
                        </slot>
                        <slot name="aplicativos">
                            <aplicativos />
                        </slot>
                    </template>
                    <slot name="usuario">
                        <usuario />
                    </slot>
                </ul>
            </div>
        </nav>
    </header>
</template>
<script>
// import Versao from './Versao'
import Notificacoes from './Notificacoes'
import Ajuda from './Ajuda'
import Configuracoes from './Configuracoes'
import Aplicativos from './Aplicativos'
import Usuario from './Usuario'
import { mostraMenu, escondeMenu } from '../../functions/menu'
export default {
    components: {
        // Versao,
        Notificacoes,
        Ajuda,
        Configuracoes,
        Aplicativos,
        Usuario
    },
    watch: {
        $route(to, from) {
            if (to.name != from.name) {
                if (this.$isMobile() || to.meta.fullScreen) {
                    escondeMenu()
                } else {
                    mostraMenu()
                }
            }
        }
    },
    mounted() {
        if (this.$isMobile() || this.$route.meta.fullScreen) {
            escondeMenu()
        }
        if (['hml', 'dev'].includes(this.$usuarioLogado.Empresa.versao_app)) {
            console.log(process.env.VUE_APP_API_URL)
            console.log(this.$usuarioLogado.Empresa.versao_app);
        }
    },
    methods: {
        alternarBarraLateral() {
            const leftSide = document.querySelector('.left-side')
            if (leftSide.classList.contains('collapse-left')) {
                mostraMenu()
            } else {
                escondeMenu()
            }
        }
    }
}
</script>
<style>
header.header > .dropdown {
    float: left;
}
header.header .dropdown > a {
    padding: 15px 15px 8px 15px;
    display: block;
}
header.header .dropdown-toggle:after {
    content: none;
}
header.header .navbar {
    position: relative;
    min-height: 50px;
    display: block;
    flex-wrap: unset;
    padding: 0;
}
header.header .navbar-nav {
    flex-direction: unset;
}
header.header .navbar-right {
    float: right;
}
header.header .navbar-nav .user-menu .dropdown-menu {
    padding: 0;
}
</style>
