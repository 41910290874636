<template>
    <b-nav-item-dropdown class="user user-menu" right ref="navdropdown">
        <template #button-content>
            <div class="d-flex">
                <div v-if="possuiFoto"
                    class="user-photo text-center" v-b-tooltip.hover :title="usuarioLogado['nome']"
                    :style="estilizaUsuario()">
                    <b-img :src="usuarioLogado['caminho_foto']" rounded="circle" :alt="usuarioLogado['nome']" />
                </div>
                <div v-else
                    class="user-photo text-center" v-b-tooltip.hover :title="usuarioLogado['nome']"
                    :style="estilizaUsuario()">
                    <b>{{ pegaIniciais(usuarioLogado['nome']) }}</b>
                </div>
            </div>
        </template>
        <li class="user-header bg-light-blue">
            <img :src="usuarioLogado['caminho_foto']" class="img-circle" :alt="usuarioLogado['nome']" />
            <p>
                {{ usuarioLogado['nome'] }}
                <small> Membro desde {{ usuarioLogado['cadastrado_em'] }} </small>
            </p>
        </li>
        <li class="user-footer">
            <div class="pull-left">
                <button type="button" class="btn btn-default btn-flat" @click="meusDados()">Meus dados</button>
            </div>
            <div class="pull-right">
                <a v-if="$route.meta.botaoSairPdv" :href="`${this.$urlBase}/vendas_balcao`" class="btn btn-default btn-flat"> Ir para ERP </a>
                <a v-else @click.prevent.stop="logout" class="btn btn-default btn-flat"> Sair </a>
            </div>
        </li>
    </b-nav-item-dropdown>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState('usuarios', ['usuarioLogado']),
        possuiFoto() {
            return !this.usuarioLogado['caminho_foto'].includes('sem-foto.png');
        }
    },
    methods: {
        ...mapActions('usuarios', {
            xLogout: 'logout'
        }),
        async logout() {
            this.$refs.navdropdown.hide()
            this.$loading.show()
            const logout = await this.xLogout()
            if (logout.status == 'success') {
                location.href = this.$urlBase + '/login'
            } else {
                this.$loading.hide()
                this.$dialog.error(logout.message)
            }
        },
        meusDados() {
            this.$refs.navdropdown.hide()
            this.$router.push(this.$urlBase + '/usuarios/meus_dados').catch(() => {})
        },
        pegaIniciais(nome) {
            const iniciais = nome
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase())
                .join("");

            return iniciais.length > 2 ? iniciais.slice(0, 2) : iniciais;
        },
        estilizaUsuario() {
            let css = {
                backgroundColor: '#efefef',
                borderRadius: "50%",
                border: "1px solid",
                color: '#444444',
                borderColor: this.$app.cor_primaria
            }
            return css;
        },
    }
}
</script>
<style scoped>
.navbar-nav .user-menu .dropdown-menu {
    margin: 0;
}
.user-photos-container {
    display: flex;
    flex-wrap: wrap;
}

.user-photo {
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
}
</style>
