<template>
    <b-nav-item-dropdown class="help-menu" id="topo-aplicativos" right ref="navdropdown">
        <template #button-content>
            <div class="d-flex align-items-center">
                <i class="fa fa-th mr-1"></i>
                <span class="mr-1">{{ integracaoAtual.nome }}</span>
                <i class="fa fa-caret-down" />
            </div>
        </template>

        <!-- Meus aplicativos -->
        <li class="header" style="font-size: 17px">
            <span v-if="minhasIntegracoes.length > 0">Meus aplicativos</span>
            <span v-else>Recomendações</span>
        </li>

        <div class="row m-0 p-2" v-if="minhasIntegracoes.length > 0">
            <div v-for="(integracao, i) in minhasIntegracoes"
                style="padding: 0.50rem !important;"
                class="col-xs-6 col-sm-4 p-0 text-center item-app mb-2"
                :class="i > 2 ? 'mt-3' : 'mt-1'"
                :key="integracao.id"
                @mouseover="integracao.mostrarNomeCompleto = true"
                @mouseleave="integracao.mostrarNomeCompleto = false"
            >
                <b-dropdown-item
                    :to="
                        integracao.link
                            ? integracao.link
                            : integracao.plugin
                    "
                >
                    <div class="text-center">
                        <img
                            :src="`${$app.url_arquivos}/img/integracoes/${integracao.plugin}.png`"
                            width="80%"
                        />
                    </div>
                    <span v-if="!integracao.mostrarNomeCompleto" class="nome-app">
                        {{ abreviaString(integracao.nome, 9) }}
                    </span>
                    <span v-else class="nome-app nome-app-completo">
                        {{ integracao.nome }}
                    </span>
                </b-dropdown-item>
            </div>
        </div>
        
        <div class="row m-0 p-2" v-else>
            <div v-for="(integracao, i) in integracoes"
                style="padding: 0.50rem !important;"
                class="col-xs-6 col-sm-4 p-0 text-center item-app mb-1"
                :class="i > 2 ? 'mt-3' : 'mt-1'"
                :key="integracao.id"
                @mouseover="integracao.mostrarNomeCompleto = true"
                @mouseleave="integracao.mostrarNomeCompleto = false"
            >
                <b-dropdown-item
                    :to="
                        integracao.link
                            ? integracao.link
                            : integracao.plugin
                    "
                >
                    <div class="text-center">
                        <img
                            :src="`${$app.url_arquivos}/img/integracoes/${integracao.plugin}.png`"
                            width="80%"
                        />
                    </div>
                    <span v-if="!integracao.mostrarNomeCompleto" class="nome-app">
                        {{ abreviaString(integracao.nome, 9) }}
                    </span>
                    <span v-else class="nome-app nome-app-completo">
                        {{ integracao.nome }}
                    </span>
                </b-dropdown-item>
            </div>
        </div>
        <div class="w-100 mt-3">
            <b-dropdown-divider></b-dropdown-divider>
            <b-button variant="default" style="color: #444444 !important;" class="w-100 todos-apps" href="/integracoes">
                Ver todos os aplicativos
            </b-button>
        </div>
    </b-nav-item-dropdown>
</template>

<script>
import { abreviaString } from '@/shared/common/functions/texto'

export default {
    data() {
        return {
            abreviaString,
            integracoes: [],
            minhasIntegracoes: [],
            integracaoAtual: {}
        };
    },
    mounted() {
        this.carregaIntegraçoes();
    },
    methods: {
        async carregaIntegraçoes() {
            const retorno = await this.$api.post('/sistema/carrega_integracoes');

            if (retorno.status === 'success') {

                const minhasIntegracoesArray = Object.values(retorno.data.minhasIntegracoes || {});
                this.minhasIntegracoes = minhasIntegracoesArray.map((integracao) => ({
                    ...integracao,
                    mostrarNomeCompleto: false,
                }));

                const todasIntegracoesArray = Object.values(retorno.data.integracoes || {});
                this.integracoes = todasIntegracoesArray.map((integracao) => ({
                    ...integracao,
                    mostrarNomeCompleto: false,
                })).slice(0,12);

                const nomeSemBarra = this.$urlBase.startsWith('/') ? this.$urlBase.slice(1) : this.$urlBase
                this.integracaoAtual = todasIntegracoesArray.find(integracao => integracao.plugin == nomeSemBarra)
                if(!this.integracaoAtual){
                    this.integracaoAtual = {
                        nome: 'Aplicativos'
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
fieldset {
    position: relative;
    border: none;
    margin-bottom: 1em;
    padding: 0 10px 10px 10px;
    background: transparent;
}
button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
}

::v-deep .todos-apps {
    border: none;
}

::v-deep .menu-integracoes {
    text-align: center;
    font-size: 10px;
    flex-wrap: wrap;
    width: 650px;
}
::v-deep .menu-integracoes.show {
    display: flex;
    flex-wrap: wrap;
    left: -280px;
}
::v-deep .dropdown-menu {
    max-height: 350px;
    min-width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
}

::v-deep .dropdown-menu::-webkit-scrollbar {
    width: 5px;
}

::v-deep .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::v-deep .dropdown-menu::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::v-deep .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::v-deep .dropdown-menu > li > a {
    padding: 5px;
}
::v-deep .dropdown-divider {
    margin-bottom: 0;
}
::v-deep .dropdown-item {
    padding: 0 !important;
}

::v-deep .item-app {
    position: relative;
}

::v-deep .nome-app {
    color: #444444;
    font-size: 13px;
    white-space: normal !important;
    position: fixed;
    margin-top: 15px;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

::v-deep .nome-app-completo {
    text-align: center;
    width: 33%;
}

::v-deep .nome-app span {
    display: block;
}
</style>
