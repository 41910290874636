var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-nav-item-dropdown',{ref:"navdropdown",staticClass:"notifications-menu",attrs:{"right":"","id":"topo-notificacoes"},on:{"show":_vm.carregaNotificacoes},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fa fa-bell"}),(_vm.notificacoesNovas > 0)?_c('span',{staticClass:"badge badge-warning"},[_vm._v(" "+_vm._s(_vm.notificacoesNovas)+" ")]):_vm._e()]},proxy:true}])},[(_vm.carregandoNotificacoes)?[_c('li',{staticClass:"text-center"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/img/carregando_mini.gif"}})])])]:(_vm.notificacoes.length > 0)?[_c('li',{staticClass:"header"},[_c('b',[_vm._v("Notificações")])]),_c('li',[_c('ul',{staticClass:"menu"},_vm._l((_vm.notificacoes),function(notificacao){return _c('li',{key:notificacao.Notificacao.id},[_c('a',{attrs:{"href":'/' 
                            + (notificacao.Notificacao.plugin 
                                ? notificacao.Notificacao.plugin + '/' 
                                : ''
                            ) 
                            + notificacao.Notificacao.controller 
                            + '/' 
                            + notificacao.Notificacao.action 
                            + '/' 
                            + notificacao.Notificacao.registro_id
                            + (notificacao.Notificacao.referencia 
                                ? `?referencia=${notificacao.Notificacao.referencia}`
                                : ''
                            )},on:{"click":function($event){return _vm.$refs.navdropdown.hide()}}},[_c('p',{class:(notificacao.Notificacao.nova == 1 
                                    ? 'bold' 
                                    : ''
                                )},[_vm._v(" "+_vm._s(notificacao.Notificacao.descricao)+" ")]),_c('small',{staticClass:"text-muted"},[_c('i',{staticClass:"fa fa-clock-o"}),_vm._v(" "+_vm._s(notificacao.Notificacao.cadastrado_em)+" ")])])])}),0)]),_c('li',{staticClass:"footer"},[_c('a',{attrs:{"href":"/notificacoes"}},[_vm._v(" Ver todas as notificações ")])])]:_vm._e(),(!_vm.carregandoNotificacoes && _vm.notificacoes.length < 1)?[_c('li',{staticClass:"header"},[_vm._v(" Você não tem notificações ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }